/* General / Global */

@font-face {
  font-family: 'Love Ya Like A Sister';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./fonts/love-ya-like-a-sister.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'VAG Rounded';
  src: url('./fonts/vag-regular.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}

@font-face {
  font-family: 'VAG Rounded';
  src: url('./fonts/vag-light.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

* {
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: 'Vag Rounded', sans-serif;
  position: fixed;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 100%;
  background: var(--blue);
  font-size: 17px;
  color: var(--body-black);
}

#root {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  display: block;
  width: 100%;
  height: 100%;
}

main,
.lhm-body {
  background: white;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 60px;
  left: 0;
  z-index: 2;
  display: block;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  border-bottom-right-radius: 45px;
  border-bottom-left-radius: 45px;
  color: var(--body-black);
}

.ios .lhm-body {
  bottom: 85px;
}

.container-20px {
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 500px) {
  .lhm-body,
  main,
  .admin-page-container,
  .chat-window {
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
  }
  .lhm-body::-webkit-scrollbar,
  main::-webkit-scrollbar,
  .admin-page-container::-webkit-scrollbar,
  .chat-window::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}

@media screen and (min-width: 800px) {
  body {
    background: white;
  }
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  margin-top: 0;
}

p {
  margin-top: 0;
}

.text-right {
  text-align: right;
}

.button {
  border-radius: 100px;
  color: white;
  font-weight: 600;
  display: block;
  width: 100%;
  padding: 16px;
  text-align: center;
  font-size: 22px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button svg {
  margin-left: 6px;
  width: auto;
  height: 18px;
}

.svg-button {
  appearance: none;
  background: none;
  border: none;
  display: block;
}

.svg-button svg {
  display: block;
}

.glow-element-button.fixed-button {
  position: fixed;
  bottom: 70px;
  width: calc(100% - 40px);
  z-index: 1;
}

.text-center {
  text-align: center;
}

/* Heading Bar */

.heading-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 50px;
  position: sticky;
  top: 0;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  z-index: 4;
}

.ios .heading-bar {
  padding-top: 48px;
}

.heading-bar-background-1,
.heading-bar-background-2 {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: -1;
}

.heading-bar-background-2 {
  opacity: 0.3;
  background: var(--blue-red-general);
  -webkit-filter: blur(44px);
  filter: blur(44px);
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  z-index: 3;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  overflow: visible;
}

.heading-bar .heading-bar-background-2 {
  z-index: -2;
}

.heading-bar p {
  margin: 0;
}

.page-heading {
  font-size: 20px;
  color: var(--heading-black);
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.single-chat-container .profile-pic {
  font-size: 22px;
  margin-right: 15px;
}

.heading-bar-sides {
  flex-grow: 1;
  flex-basis: 0;
}

.heading-bar-sides:not(:first-of-type) button {
  margin-left: auto;
}

.heading-bar-button {
  padding: 0 20px;
}

.glow-top {
  height: 50px;
  left: -10%;
  width: 120%;
  position: fixed;
}

.ios .glow-top {
  height: 100px;
}

/* Box Component */

.box-component {
  border-radius: 17px;
  background: white;
  position: relative;
  padding: 20px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.box-component::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-filter: blur(34px);
  filter: blur(34px);
  z-index: -1;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

/* Navigation */

nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  padding-bottom: 3px;
  background: var(--light-grey);
  padding-top: 60px;
}

.ios nav {
  padding-bottom: 25px;
}

nav a {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--grey);
  font-size: 14px;
}

.ios nav a {
  font-size: 12px;
}

nav a svg {
  margin-bottom: 1px;
}

.ios nav a svg {
  margin-bottom: 2px;
}

nav a.current-link {
  color: var(--blue);
}

/* Glow effect */

.glow-element {
  position: relative;
}

.glow-element::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.2;
  background: var(--blue-red-general);
  z-index: -1;
  -webkit-filter: blur(17px);
  filter: blur(17px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

.glow-element-logo,
.glow-element-button {
  position: relative;
  transition: filter 300ms ease;
}

.glow-element-button.loading {
  pointer-events: none;
  filter: grayscale(0.6);
}

.glow-element-button.loading .button {
  height: 57.3px;
}

.glow-element-logo img:first-of-type,
.glow-element-logo svg:first-of-type,
.glow-element-button .button {
  position: relative;
  z-index: 1;
}

.glow-element-logo img:last-of-type,
.glow-element-logo svg:last-of-type,
.glow-element-button .button-glow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  filter: blur(24px);
  opacity: 0.5;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.glow-element-logo img:last-of-type,
.glow-element-logo svg:last-of-type {
  filter: blur(18px) brightness(1.3) saturate(1.8);
}

.glow-element-button .button-glow {
  transition: all 300ms ease;
  filter: blur(14px) brightness(1.3) saturate(1.5);
  opacity: 0.5;
  border-radius: 100px;
}

.glow-element-button:hover .button-glow {
  filter: blur(20px) brightness(1.3) saturate(1.5);
  opacity: 0.8;
}

.glow-element-button.blue .button {
  background: linear-gradient(180deg, #007faf 0%, #00a1c8 100%);
}

.glow-element-button.blue .button-glow {
  background: linear-gradient(180deg, #007faf 0%, #00a1c8 100%);
}

.glow-element-button.red .button {
  background: linear-gradient(180deg, #cb0935 0%, #fc577e 100%);
}

.glow-element-button.red .button-glow {
  background: linear-gradient(180deg, #cb0935 0%, #ff5c82 100%);
}

.glow-element-button.blue-red .button {
  background: linear-gradient(180deg, #cb0935 17%, #007faf 100%);
}

.glow-element-button.blue-red .button-glow {
  background: linear-gradient(180deg, #cb0935 17%, #007faf 100%);
}

.glow-element-button {
  margin-bottom: 22px;
}

.glow-element-button.blue-outline .button {
  background: transparent;
  box-shadow: inset 0px 0px 0px 2px var(--blue);
  color: var(--blue);
}

.glow-element-button.blue-outline .button-glow {
  display: none;
}

/* Loading */

.loading-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('./images/onboarding-mobile-background.jpg');
  background-size: cover;
}

.orange-heading {
  font-size: 40px;
  font-family: 'Love Ya Like A Sister';
  font-weight: 400;
  color: var(--orange);
}

.loading-text-page {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 177px;
}

.loading-text-page:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026'; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 0.8em;
  }
}

/* Desktop Layout */
.desktop-app-title {
  display: none;
}

@media screen and (min-width: 800px) {
  nav {
    top: 0;
    bottom: unset;
    z-index: 5;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom-right-radius: 45px;
    border-bottom-left-radius: 45px;
    background: rgba(255, 255, 255, 0.77);
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 12px;
    justify-content: flex-end;
    align-items: center;
    backdrop-filter: blur(6px);
  }
  nav a {
    margin-right: 50px;
  }
  .lhm-body {
    z-index: 1;
    bottom: 0;
    top: 0px;
    border-radius: 0;
    padding-top: 100px;
  }
  .desktop-app-title {
    display: block;
    margin: 0;
    margin-right: auto;
    margin-left: 30px;
    margin-bottom: -5px;
  }
  .desktop-app-title .logo-container {
    display: flex;
    align-items: center;
    color: var(--blue);
    font-size: 16px;
  }
  .desktop-app-title .logo-container .glow-element-logo {
    margin-right: 15px;
    margin-bottom: 0;
    width: 50px;
    height: auto;
  }
  .desktop-app-title .logo-container h1 {
    margin: 0;
  }
}

/* Notices / Errors */

.notices-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
}

.notices-container.below {
  top: unset !important;
  bottom: 190px;
}

.notice-overlay {
  border-radius: 35px;
  padding: 13px 10px;
  font-size: 18px;
  width: 100%;
  max-width: 500px;
  margin-top: 16px;
  text-align: center;
  border: 2px solid;
  font-weight: 600;
  background: white;
  position: relative;
}

.notice-overlay svg {
  position: absolute;
  top: -13px;
  right: -4px;
  background: white;
  border-radius: 50%;
}

svg path {
  pointer-events: none;
}

.notice-overlay:first-of-type {
  margin-top: 40px;
}

.notice-overlay a,
.notice-overlay span {
  text-decoration: underline;
  padding-bottom: 1px;
}

@media screen and (min-width: 800px) {
  .notices-container.below {
    bottom: 130px;
  }
}

/* Onboarding */

.onboarding-container {
  background: url('./images/onboarding-mobile-background.jpg');
  background-size: cover;
  height: 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--blue);
}

.onboarding-content-container {
  position: relative;
  width: 100%;
  margin-top: auto;
  background: white;
  transition: transform 0.5s ease;
  transform-origin: bottom;
  box-shadow: 0px 0px 150px 5px rgba(0, 127, 175, 0.35);
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
}

.onboarding-content-background {
  background: white;
  transition: transform 0.5s ease;
  transform-origin: bottom;
  z-index: 0;
  position: absolute;
  width: 100%;
  box-shadow: 0px 0px 150px 5px rgba(0, 127, 175, 0.35);
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
}

.onboarding-content-inner {
  padding: 20px;
  z-index: 1;
  position: relative;
}

.logo-container {
  margin: auto;
  overflow: visible;
}

.onboarding-container h1:not(.page-heading) {
  letter-spacing: -0.01em;
  font-size: 31px;
  margin: 0;
  text-align: center;
}

.onboarding-content-inner h1:not(.page-heading) {
  margin-bottom: 40px;
}

.logo-container .glow-element-logo {
  display: block;
  width: 70%;
  margin: 0 auto 10px;
  max-width: 150px;
  overflow: visible;
}

.logo-container .glow-element-logo img {
  width: 100%;
  height: auto;
}

.start-p {
  font-size: 22px;
  line-height: 1.2;
  text-align: center;
  max-width: 240px;
  margin: 30px auto 50px;
}

.reset-password-link {
  color: var(--red);
  font-weight: 600;
  text-align: center;
  display: block;
  margin: 30px auto 25px;
}

/* Age Selector */

.age-selector {
  margin-top: 18vh;
  margin-bottom: calc(18vh + 30px);
  margin-left: -20px;
  margin-right: -20px;
  position: relative;
}

.age-selector::before,
.age-selector::after {
  content: '';
  width: 120px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  transform: rotate(-180deg);
  background: linear-gradient(270deg, #ffffff -27.54%, rgba(255, 255, 255, 0) 113.14%);
  z-index: 1;
}

.age-selector::after {
  left: unset;
  right: 0;
  transform: rotate(0deg);
}

.age-selector-logo img {
  display: block;
  width: 60px;
  height: auto;
  margin: 0 auto;
}

.age-scroller {
  display: flex;
  flex: none;
  flex-flow: row nowrap;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  will-change: scroll-position;
}

.age-spacer {
  width: calc(50% - 30px);
  flex-shrink: 0;
}

.age-scroller::-webkit-scrollbar {
  height: 0.4rem;
  width: 0.4rem;
  display: none;
}

.age-scroller .age-container {
  width: 80px;
  height: 70px;
  text-align: center;
  flex-shrink: 0;
  position: relative;
}

.age-scroller .age-container .number {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  font-weight: 600;
  font-size: 60px;
  scroll-snap-type: unset;
  scroll-snap-align: unset;
  opacity: 0.5;
  transform: scale(0.8);
  transform-origin: center;
  will-change: transform;
  transition: all 300ms ease;
}

.age-scroller .age-container.current-age .number {
  opacity: 1;
  transform: scale(1);
}

/* Safari only – scroll snap not working well in Chrome */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .age-scroller {
      scroll-snap-type: x mandatory;
    }
    .age-scroller div {
      scroll-snap-align: center;
    }
  }
}

/* Terms */

.terms-page {
  position: fixed;
  bottom: 0;
}

.terms-page .container-20px {
  padding-top: 20px;
  padding-bottom: 20px;
}

/* Onboarding Form */

input {
  border: none;
  -webkit-appearance: none;
  border-radius: 100px;
  padding: 11px 18px;
  box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.11);
  width: 100%;
  margin-bottom: 18px;
  font-size: 22px;
  color: #7e7e7e;
  outline: none;
}

.smaller-inputs input {
  font-size: 17px;
  padding: 10px 16px;
}

form:not(.smaller-inputs) input:last-of-type {
  margin-bottom: 50px;
}

::placeholder {
  color: #b2b2b2;
}

.onboarding-form h3 {
  font-size: 22px;
  color: var(--red);
}

.onboarding-form input[name='userLastName'] {
  margin-bottom: 30px;
}

/* Date Input */

.dob-input-group {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  border: none;
  padding: 0;
  margin: 0;
}

.dob-input-group legend {
  flex: 1 0 100%;
  font-size: 22px;
  font-weight: 600;
  color: var(--grey);
  margin-left: 22px;
}

.dob-input-group input {
  text-align: center;
  margin-top: 18px !important;
  margin-bottom: 50px !important;
  letter-spacing: 2px;
}

.dob-input-group input[name='MM'],
.dob-input-group input[name='DD'] {
  width: calc(35% - 8px);
}

.dob-input-group input[name='MM'] {
  margin-left: 12px;
  margin-right: 12px;
}

.dob-input-group input[name='YYYY'] {
  width: calc(40% - 8px);
}

/* Checkbox */

.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  margin-bottom: 35px;
}

.checkbox-container .checkbox {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 13px;
  flex-shrink: 0;
  box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.11);
}

.checkbox-container .checkbox.checked {
  background: linear-gradient(180deg, #007faf 0%, #00a1c8 100%);
  box-shadow: 0px 0px 17px rgb(0 159 198);
}

.checkbox-container.green .checkbox.checked {
  background: var(--green);
  box-shadow: 0px 0px 17px var(--green);
}

.checkbox-container.blue-red .checkbox.checked {
  background: var(--blue-red-general);
  box-shadow: 0px 0px 17px var(--blue);
}

.checkbox-container.red .checkbox.checked {
  background: var(--red);
  box-shadow: 0px 0px 17px var(--red);
}

.checkbox-container p {
  margin: 0;
  color: var(--red);
}

.checkbox-container p a {
  color: var(--red);
  font-weight: 600;
}

input[type='checkbox'] {
  display: none;
}

/* Toggle Switch */

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid var(--red);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  bottom: 2px;
  border: 1px solid var(--red);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch input:checked + .slider {
  border-color: var(--blue);
  box-shadow: 0px 0px 17px rgb(0 159 198 / 36%);
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px var(--blue);
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(23px);
  -ms-transform: translateX(23px);
  transform: translateX(23px);
  background: linear-gradient(180deg, #007faf 0%, #00a1c8 100%);
  box-shadow: 0px 0px 17px rgb(0 159 198);
  border: none;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Success Page */

.loading-spinner-blue-container {
  padding-top: 30px;
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-icon {
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
}

.error-icon svg {
  width: 100px;
  height: auto;
  margin-bottom: 40px;
}

h1.success-heading {
  margin-top: 70px !important;
}

.success-message {
  font-size: 20px;
  margin-bottom: 40px;
}

.success-tick {
  position: absolute;
  top: -97px;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
}

/* Onboarding Desktop */

@media screen and (min-width: 800px) {
  .loading-container,
  .onboarding-container {
    background: url('./images/onboarding-desktop-background.jpg');
    background-size: cover;
  }
  .onboarding-content-container {
    max-width: 450px;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 40px;
  }
  .logo-container {
    margin-top: auto;
    margin-bottom: 0px;
  }
  .onboarding-content-inner {
    padding: 35px;
    padding-bottom: 20px;
  }
  .age-selector {
    margin-left: -35px;
    margin-right: -35px;
  }
  .terms-page .container-20px {
    max-width: 570px;
    margin: 0 auto;
  }
}

/* Home */

.ios .home-body-container {
  padding-top: 40px;
}

.welcome-message {
  font-family: 'Love Ya Like A Sister';
  font-weight: 400;
  margin-top: 20px;
}

.icon-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.icon-heading h2 {
  display: flex;
  align-items: center;
  margin: 0;
}

.icon-heading h2 svg {
  margin-right: 10px;
}

.icon-heading a {
  font-size: 18px;
}

.home-container .box-component {
  padding: 0;
}

.box-component .user-list-item {
  padding-top: 0;
}

.broadcast-message {
  overflow: hidden;
  white-space: pre-wrap;
  margin-bottom: 0;
}

.broadcast-message.has-read-more {
  max-height: 40px;
}

.broadcast-message.expanded {
  max-height: none;
}

.read-more-link {
  font-weight: 600;
  color: var(--blue);
  margin-top: 15px;
  display: block;
}

@media screen and (min-width: 800px) {
  /* .home-body-container .container-20px,
  .event-body .container-20px,
  .profile-bottom.container-20px,
  .edit-profile .container-20px,
  .settings-container .container-20px,
  .support-container .container-20px */
  .container-20px,
  .user-list-item {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  /* .profile-bottom.container-20px .glow-element-button.fixed-button,
  .edit-profile .container-20px .glow-element-button.fixed-button,
  .settings-container .container-20px .glow-element-button.fixed-button,
  .admin-page-container   */
  .glow-element-button.fixed-button {
    width: 500px;
    right: 0;
    left: 0;
    bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Search Bar */

.search-bar {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
}

.search-bar::after {
  border-radius: 100px;
  filter: blur(14px);
  opacity: 0.15;
}

.search-bar input {
  padding: 9px 16px 9px 45px;
  font-size: 17px;
  -webkit-appearance: none;
  background: white;
  box-shadow: none;
  margin: 0;
  height: 37px;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.search-bar button:first-of-type {
  position: absolute;
  left: 6px;
  top: 7px;
  padding-left: 6px;
  padding-right: 6px;
}

.search-bar .clear-text {
  position: absolute;
  right: 6px;
  top: 6.5px;
}

.search-bar .clear-text svg {
  height: 22px;
}

.search-bar .clear-text.hidden {
  display: none;
}

.search-bar .clear-text svg path {
  fill: var(--border-grey);
}

/* User List */

.users-list {
  margin-left: -20px;
  margin-right: -20px;
}

.user-list-item {
  padding: 15px;
  border-bottom: 1px solid var(--light-grey);
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}

.user-list-item p {
  margin: 0;
}

.user-list-item p:first-of-type {
  font-weight: 600;
}

.user-list-item .profile-pic {
  margin-right: 15px;
}

.user-list-item p svg {
  position: relative;
  top: 2px;
}

.profile-pic {
  border-radius: 50%;
  font-family: 'Love Ya Like A Sister';
  font-weight: 400;
  font-size: 33px;
  line-height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid;
  position: relative;
  background-image: url('./images/no-profile-background.jpg');
  background-size: cover;
  flex-shrink: 0;
}

.profile-pic::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  filter: blur(10px);
  opacity: 0.7;
  z-index: -2;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.profile-pic .online-dot {
  display: none;
}

.profile-pic.online .online-dot {
  display: block;
  position: absolute;
  bottom: 1px;
  right: -3px;
  width: 14px;
  height: 14px;
  border: 2px solid white;
  border-radius: 50%;
  opacity: 1;
}

.profile-pic img {
  border-radius: 50%;
  display: block;
  width: 100%;
  height: 100%;
}

/* Chats */

.chat-list-item {
  padding: 15px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}

.chat-list-item.open-chat {
  background: #f6f6f6;
}

/* Pulse animation */
@keyframes pulse {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.7;
  }
}

@keyframes pulseSoft {
  0% {
    opacity: 0.25;
  }
  100% {
    opacity: 0.4;
  }
}

.chat-list-item .unread-dot {
  display: block;
  position: absolute;
  top: 50%;
  left: 10px;
  width: 8px;
  height: 8px;
  transform: translateY(-50%);
  border-radius: 50%;
  opacity: 1;
}

.home-container .chat-list-item .unread-dot {
  left: 7px;
}

.chat-list-item .unread-dot::after {
  content: '';
  position: absolute;
  top: -22px;
  left: -60px;
  width: 130px;
  height: 50px;
  border-radius: 50%;
  filter: blur(30px);
  opacity: 1;
  z-index: -1;
  backface-visibility: hidden;
  animation: pulse 1.2s alternate infinite;
}

.chat-list-item .unread-dot.blue-red::after {
  animation: pulseSoft 1.2s alternate infinite;
}

.chat-list-item::after {
  content: '';
  position: absolute;
  width: calc(100% - 80px);
  right: 0;
  bottom: 0;
  border-bottom: 1px solid var(--light-grey);
}

.chat-list-item:has(+ .chat-list-item.open-chat)::after {
  content: none;
}

.chat-list-item .profile-pic {
  margin-right: 18px;
  margin-left: 14px;
}

.chat-list-item .profile-pic.blue-red,
.chat-list-item .profile-pic.orange-pink {
  margin-left: 16px;
}

.home-container .chat-list-item .profile-pic {
  margin-left: 5px;
}

.chat-list-text-container {
  flex: 1;
}

.chat-list-text-container p {
  margin: 0;
}

.chat-list-text-container p {
  color: var(--border-grey);
  font-size: 16px;
}

.chat-list-text-container p:first-of-type {
  color: var(--body-black);
  font-weight: 600;
  font-size: 17px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.chat-list-text-container p:first-of-type .chat-list-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100vw - 194px);
}

.chat-list-text-container p .chat-subtitle {
  margin-right: auto;
  margin-left: 5px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100vw - 244px);
}

.home-container .chat-list-text-container p .chat-subtitle {
  max-width: calc(100vw - 279px);
}

.chat-list-text-container p .message-timestamp {
  font-weight: 400;
  color: var(--border-grey);
  font-size: 15px;
}

@media screen and (max-width: 350px) {
  .chat-list-text-container p:first-of-type {
    font-size: 16px;
  }
  .chat-list-text-container p .message-timestamp {
    font-size: 13px;
  }
  .chat-list-text-container p .chat-subtitle {
    max-width: calc(100vw - 238px);
  }
}

.contacts-body {
  z-index: 3;
}

.create-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 26px 15px 0;
  height: 36px;
  border-bottom: 1px solid var(--light-grey);
}

.create-group svg {
  margin-right: 2px;
  height: 20px;
}

.select-user {
  margin-left: auto;
}

.select-user .checkbox-container {
  margin: 0;
}

.select-user .checkbox-container .checkbox {
  width: 25px;
  height: 25px;
}

/* Single Chat */

.single-chat-container {
  bottom: 0;
  border-radius: 0;
  z-index: 5;
}

.single-chat-container .notices-container {
  top: 30px;
}

.ios .single-chat-container .notices-container {
  top: 60px;
}

.single-chat-container .loading-spinner-blue-container {
  background: url('./images/chat-background-mobile.jpg');
  background-position: center center;
  background-size: cover;
  position: absolute;
  height: calc(100% - 70px);
  min-height: calc(100% - 210px);
  top: 0;
  right: 0;
  bottom: 0px;
  left: 0;
  z-index: 2;
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  border-bottom-right-radius: 45px;
  border-bottom-left-radius: 45px;
  margin-top: unset;
  margin-bottom: unset;
}

.single-chat-container .fixed-button {
  bottom: 20px;
}

@media screen and (min-width: 800px) {
  .single-chat-container .fixed-button {
    left: 35%;
  }
}

/* Chat Input Bar */

.chat-input-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: var(--light-grey);
  padding: 80px 20px 15px;
  z-index: 2;
}

.ios .chat-input-bar {
  padding-bottom: 25px;
}

.chat-text-input {
  width: 100%;
  height: 36px;
  background: #ffffff;
  box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.11);
  border-radius: 20px;
  outline: none;
  padding: 8px 40px 8px 10px;
  border: none;
  overflow: hidden;
  resize: none;
  max-height: 172px;
  -webkit-appearance: none;
}

.chat-text-input.sending {
  background: var(--light-grey);
}

.send-button {
  position: absolute;
  right: 32px;
  bottom: 24px;
  padding: 0;
}

.send-button svg,
.camera-button svg {
  width: 24px;
  height: auto;
}

.camera-button {
  position: absolute;
  right: 69px;
  bottom: 24px;
  padding: 0;
  cursor: pointer;
}

.ios .camera-button,
.ios .send-button {
  bottom: 35px;
}

#image-message-file {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  padding: 0;
  margin: 0;
}

.picture-message-box {
  width: 100%;
  height: 200px;
  background: #ffffff;
  box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.11);
  border-radius: 20px;
  padding: 12px;
}

.picture-message-container {
  position: relative;
  width: fit-content;
  height: 176px;
  max-width: 80%;
}

.picture-message-container img {
  display: block;
  height: 100%;
  width: auto;
  border-radius: 15px;
  max-width: 100%;
  object-fit: cover;
}

.remove-image {
  mix-blend-mode: exclusion;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
  padding: 0;
}

.remove-image svg {
  width: 30px;
  height: 30px;
}

/* Chat Window */

.chat-window {
  background: url('./images/chat-background-mobile.jpg');
  background-position: center center;
  background-size: cover;
  position: absolute;
  height: calc(100% - 70px);
  min-height: calc(100% - 230px);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  display: flex;
  flex-direction: column-reverse;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  border-bottom-right-radius: 45px;
  border-bottom-left-radius: 45px;
  padding-top: 50px;
}

.ios .chat-window {
  padding-top: 100px;
  bottom: unset;
  height: calc(100% - 0px);
}

.single-chat-header {
  display: flex;
  align-items: center;
}

.chat-header-p {
  margin-top: -5px;
  margin-bottom: -5px;
  line-height: 0.9;
}

.message-box {
  background: white;
  border-radius: 17px;
  position: relative;
  width: 70%;
  margin-left: 41px;
  padding: 8px 13px;
  white-space: pre-wrap;
  margin-top: 15px;
  margin-bottom: 15px;
}

.message-box.from-me {
  color: white;
}

.community .message-box:not(.from-me):not(.system),
.group .message-box:not(.from-me):not(.system) {
  margin-top: 35px;
}

.last-message-from-same.message-box {
  margin-top: 5px !important;
}

.next-message-from-same.message-box {
  margin-bottom: 5px !important;
}

.message-box:not(.system):nth-last-of-type(2) {
  margin-top: 60px !important;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .message-box.system:nth-last-of-type(2) {
      border-top: 70px solid transparent;
    }
  }
}

.message-box.system:nth-last-of-type(2) {
  margin-top: 20px !important;
}

.message-box:nth-of-type(2) {
  margin-bottom: 30px !important;
}

.message-box.removed {
  opacity: 0.4;
  border: 1px solid var(--border-grey);
}

.scroller {
  scroll-margin-bottom: 30px;
}

.messages-lazy-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 42px;
  z-index: 13;
}

.message-box:not(.system) {
  -webkit-user-select: text;
  user-select: text;
}

.message-box::after {
  background: var(--grey);
  border-radius: 17px;
  opacity: 0.3;
  -webkit-filter: blur(10px);
  filter: blur(10px);
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.message-box .profile-pic {
  position: absolute;
  left: -30px;
  bottom: -8px;
  margin: 0 !important;
  font-size: 17px;
}

.message-box .profile-pic.blue-red {
  left: -28.5px;
}

.message-box.from-me .profile-pic {
  left: unset;
  right: -30px;
}

.message-box.from-me .profile-pic.blue-red {
  right: -28.5px;
}

.message-box.from-me {
  margin-left: auto;
  margin-right: 41px;
}

.message-box.system {
  background: transparent;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-weight: 600;
  padding: 0;
}

.message-box.system::after {
  content: none;
}

.chat-window .message-timestamp {
  font-size: 14px;
  text-align: center;
  color: var(--grey);
  margin: 15px auto;
}

.message-name {
  font-size: 14px;
  color: var(--grey);
  margin-bottom: 5px;
  position: absolute;
  top: -19px;
}

.image-in-message {
  display: block;
  width: calc(100% + 10px);
  height: auto;
  border-radius: 11px;
  margin-left: -5px;
}

@media screen and (min-width: 800px) {
  .single-chat-container .loading-spinner-blue-container {
    background-image: url('./images/chat-background-desktop.jpg');
  }
  .chat-window {
    background-image: url('./images/chat-background-desktop.jpg');
  }
  .message-box {
    max-width: 390px;
  }
}

/* Admin Chat Edit Mode */

.chat-window.edit-mode {
  background-image: url('./images/admin-edit-mode-background.jpg');
  background-repeat: repeat;
  background-size: 70px;
}

.chat-window.edit-mode .message-box svg {
  width: 22px;
  position: absolute;
  top: -21px;
  right: -18px;
}

.chat-window.edit-mode .message-box.from-me svg {
  left: -18px;
}

.chat-window.edit-mode .message-box.system svg {
  display: none;
}

/* Chat Info */

.chat-info-container {
  bottom: 0px;
  border-radius: 0;
  z-index: 5;
}

.menu-container .menu-list-item,
.toggle-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
  position: relative;
}

.menu-container .menu-list-item::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(100% - 40px);
  height: 1px;
  background: var(--border-grey);
}

.menu-container .menu-list-item p,
.toggle-container p {
  flex: 1;
  margin-right: auto;
  margin: 0;
  font-weight: 600;
  font-size: 20px;
}

.menu-list-item svg {
  display: block;
}

.menu-container .list-item-icon svg {
  width: 25px;
  height: auto;
  margin-right: 15px;
}

.box-component .user-list-item:last-of-type {
  border-bottom: transparent;
}

.confirmation-popup-background {
  z-index: 6;
  background: #ffffffdb;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-container {
  background: white;
  width: 340px;
  max-width: 90%;
  text-align: center;
  padding: 0;
  margin: 0;
  padding-top: 15px;
  position: relative;
}

.popup-container h3 {
  margin-bottom: 10px;
}

.popup-container p {
  margin-bottom: 25px;
}

.popup-container .close-icon {
  position: absolute;
  top: -10px;
  right: -10px;
}

.popup-actions {
  display: flex;
}

.popup-actions button,
.popup-options button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  width: 50%;
  padding: 15px;
  background: white;
  border-top: 1px solid var(--border-grey);
  color: var(--black);
}

.popup-actions button:first-of-type {
  border-bottom-left-radius: 17px;
  background: radial-gradient(circle, #fce6eb 0%, rgba(255, 255, 255, 1) 100%);
  border-top: 1px solid var(--red);
  border-right: 1px solid #96969636;
}

.popup-actions button:last-of-type {
  border-bottom-right-radius: 17px;
  background: radial-gradient(circle, #d2ebf3 0%, rgba(255, 255, 255, 1) 100%);
  border-top: 1px solid #007faf;
}

.popup-loading-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 17px;
}

.popup-container.alert button {
  width: 100%;
  border-right: none;
}

.popup-options button {
  width: 100%;
}

.popup-options button:last-of-type {
  border-bottom-right-radius: 17px;
  border-bottom-left-radius: 17px;
}

.popup-options button.green {
  background: radial-gradient(circle, #13e4bf2e 0%, rgba(255, 255, 255, 1) 100%);
  border-top: 1px solid #13e4bf;
}

.popup-options button.blue {
  background: radial-gradient(circle, #007faf1f 0%, rgba(255, 255, 255, 1) 100%);
  border-top: 1px solid var(--blue);
}

.popup-options button.yellow {
  background: radial-gradient(circle, #ffc7002b 0%, rgba(255, 255, 255, 1) 100%);
  border-top: 1px solid var(--yellow);
}

.popup-options button.orange {
  background: radial-gradient(circle, #ff961c24 0%, rgba(255, 255, 255, 1) 100%);
  border-top: 1px solid var(--orange);
}

.popup-options button.red {
  background: radial-gradient(circle, #fce6eb 0%, rgba(255, 255, 255, 1) 100%);
  border-top: 1px solid var(--red);
}

.chat-notification-options {
  margin-top: 35px;
}

.chat-notification-options .checkbox-group {
  flex-direction: column;
  align-items: flex-start;
}

.chat-notification-options .checkbox-group .checkbox-container:not(:last-of-type) {
  margin-bottom: 15px;
}

.chat-notification-options .checkbox-container p {
  color: var(--black);
  font-weight: 600;
}

@media screen and (min-width: 800px) {
  .chats-list {
    border-right: 1px solid var(--light-grey);
    min-height: 100%;
  }
  .chats-main {
    width: 35%;
    max-width: 420px;
  }
  .single-chat-container {
    width: 65%;
    margin-left: auto;
    min-width: calc(100% - 420px);
  }
  .single-chat-container .notices-container {
    width: 65%;
    right: 0;
    left: unset;
    top: 130px;
    min-width: calc(100% - 420px);
  }
  .chat-input-bar {
    width: 65%;
    right: 0;
    min-width: calc(100% - 420px);
  }
}

/* Report */

.report-text-input {
  position: relative;
  border: none;
  -webkit-appearance: none;
  border-radius: 17px;
  padding: 11px 18px;
  box-shadow: 0px 0px 59px #ffdae2;
  width: 100%;
  margin-bottom: 18px;
  font-size: 19px;
  color: #7e7e7e;
  outline: none;
  height: 200px;
  margin-top: 10px;
  margin-bottom: 30px;
}

/* Profiles */

.profile-top {
  text-align: center;
  position: relative;
}

.profile-background {
  position: absolute;
  width: 100%;
  height: 85%;
  top: 0;
  left: 0;
  z-index: 1;
  clip-path: circle(95% at 50% 0%);
}

.jigsaw-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./images/jigsaw_white.png');
  background-size: cover;
  z-index: 2;
  mix-blend-mode: overlay;
  opacity: 0.1;
}

.profile-content {
  position: relative;
  z-index: 3;
  padding: 20px;
  color: white;
}

.profile-top .svg-button svg {
  width: 38px;
  height: auto;
}

.profile-top h1 {
  font-family: 'Love Ya Like A Sister';
  font-weight: 400;
  font-size: 40px;
  margin: 90px auto 0;
}

.ios .profile-top h1 {
  margin-top: 120px;
}

.profile-top h2 {
  font-family: 'Love Ya Like A Sister';
  font-weight: 400;
  font-size: 30px;
}

.profile-content p {
  margin-bottom: 0;
}

.profile-content .svg-button {
  margin-left: auto;
  position: absolute;
  top: 20px;
  right: 20px;
}

.ios .profile-content .svg-button {
  top: 50px;
}

.profile-content .svg-button.not-me {
  right: unset;
  left: 20px;
}

.profile-content .profile-pic {
  margin: 50px auto 0px;
  border-width: 8px;
  font-size: 100px;
}

.profile-content .profile-pic:after {
  filter: blur(30px);
  opacity: 1;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.profile-content .profile-pic.blue-red::before {
  top: -8.5px;
  left: -8.5px;
  width: calc(100% + 17px);
  height: calc(100% + 17px);
}

.profile-bottom {
  padding-top: 25px;
  font-size: 19px;
  padding-bottom: 130px;
}

.profile-bottom p {
  white-space: pre-wrap;
}

.edit-profile h3 {
  padding-top: 10px;
  margin-bottom: 26px;
}

.swatch-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.swatch {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: relative;
}

.swatch::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(10px);
  border-radius: 50%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.swatch.current::before {
  content: '';
  position: absolute;
  top: 6%;
  left: 6%;
  width: 88%;
  height: 88%;
  background: white;
  border-radius: 50%;
  z-index: 1;
  background-size: cover;
}

.bio-text-input {
  position: relative;
  border: none;
  -webkit-appearance: none;
  border-radius: 17px;
  padding: 11px 18px;
  box-shadow: 0px 0px 19px rgb(0 0 0 / 11%);
  width: 100%;
  margin-bottom: 18px;
  font-size: 19px;
  color: #7e7e7e;
  outline: none;
  height: 200px;
  margin-top: 10px;
  margin-bottom: 130px;
}

.small-text-input {
  font-size: 19px;
}

@media screen and (min-width: 800px) {
  .jigsaw-overlay {
    background-image: url('./images/jigsaw_white_desktop.png');
  }
}

/* Settings */

.container-20px .start-p {
  max-width: unset;
  font-size: 20px;
}

.single-chat-container .container-20px .start-p {
  text-align: left;
}

.change-password .notices-container {
  top: 30px;
}

/* Support */

.support-tiles {
  margin-bottom: 30px;
}

.support-tiles a {
  display: block;
  margin-top: 30px;
}

.support-tiles a img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 20px;
}

.container-20px .user-list-item {
  padding-left: 0;
  padding-right: 0;
}

@media screen and (min-width: 800px) {
  .support-tiles {
    display: flex;
    flex-wrap: wrap;
  }
  .support-tiles a {
    width: 48%;
  }
  .support-tiles a:nth-child(2n - 1) {
    margin-right: 4%;
  }
}

.profile-pic-upload {
  width: 100%;
  height: 46px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 30px;
  border: 1px dashed var(--border-grey);
}

.profile-pic-upload #profile-pic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.edit-profile .notice-overlay:first-of-type {
  margin-top: 80px;
}

.container-20px.loading {
  pointer-events: none;
}

/* Events */

.event-tile-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  position: relative;
  z-index: 1;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: -20px;
  margin-bottom: -20px;
  margin-left: -20px;
  margin-right: -20px;
}

.home-container .event-tile-container {
  padding-bottom: 30px;
}

.event-tile-container::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.event-tile-spacer {
  width: 20px;
  flex-shrink: 0;
}

.event-tile-spacer:last-of-type {
  width: 5px;
}

.event-tile {
  width: 200px;
  padding: 15px;
  margin-right: 25px;
  scroll-snap-align: center;
  flex-shrink: 0;
  border-radius: 20px;
  position: relative;
  z-index: 1;
  color: white;
  display: flex;
  flex-direction: column;
}

.event-tile::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(10px);
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  border-radius: 20px;
  z-index: -1;
  opacity: 0.7;
}

.event-tile p {
  margin: 0;
}

.event-name {
  margin-top: auto !important;
}

.event-date,
.event-time {
  font-family: 'Love Ya Like A Sister';
  display: block;
  font-size: 35px;
}

.event-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}

.event-body {
  color: white;
}

.event-body .event-date {
  margin-top: 30px;
}

.event-body .event-time {
  margin-bottom: 40px;
}

.event-body p {
  white-space: pre-wrap;
  margin-bottom: 0;
  padding-bottom: 30px;
}

.event-body p b {
  margin-bottom: 10px;
  display: block;
  font-size: 22px;
}

@media screen and (min-width: 800px) {
  .event-tile-container {
    flex-wrap: wrap;
    margin: unset;
    justify-content: flex-start;
    overflow: visible;
  }
  .event-tile {
    margin-bottom: 25px;
    width: 31%;
    margin-right: 3.5%;
  }
  .event-tile:nth-child(3n + 1) {
    margin-right: 0;
  }
  .event-tile-spacer {
    display: none;
  }
}

/*******************************/
/* Toast */
/*******************************/

@keyframes slideIn {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.toast {
  z-index: 6;
  position: fixed;
  top: 70px;
  left: 20px;
  width: calc(100% - 40px);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  background: rgba(247, 247, 247, 0.188);
  padding: 15px;
  border-radius: 15px;
  border: 1px solid var(--border-grey);
  display: flex;
  flex-direction: column;
  cursor: pointer;
  animation: slideIn 0.5s ease-in-out, slideOut 0.5s ease-in-out 3s;
  animation-fill-mode: forwards;
}

.ios .toast {
  top: 120px;
}

.toast p {
  margin-bottom: 0;
}

@media screen and (min-width: 800px) {
  .toast {
    top: 7px;
    left: 500px;
    width: 300px;
    padding: 10px;
  }
}

@media screen and (min-width: 1000px) {
  .toast {
    top: 5px;
    left: 500px;
    width: 500px;
    padding: 10px;
  }
}

/*******************************/
/* Colours */
/*******************************/

/* Blue Red Gradient */

.profile-pic.blue-red {
  border: none;
  color: var(--blue);
}

.user-list-item .profile-pic.blue-red {
  margin-left: 2.5px;
  margin-right: 17.5px;
}

.profile-pic.blue-red::after {
  background: var(--blue-red);
}

.profile-pic.blue-red::before {
  content: '';
  position: absolute;
  background: var(--blue-red);
  top: -2.5px;
  left: -2.5px;
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  border-radius: 50%;
  opacity: 1;
  z-index: -1;
}

.single-chat-container .profile-pic.blue-red::before {
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
}

.profile-pic.blue-red .online-dot,
.unread-dot.blue-red {
  background: var(--blue);
}

.unread-dot.blue-red::after {
  background: var(--red);
}

.heading-bar-background-2.blue-red {
  background: var(--blue-red-general);
}

.message-box.from-me.blue-red {
  background: var(--blue-gradient);
}

.message-box.from-me.blue-red::after {
  opacity: 0.8;
  background: var(--blue-message-glow);
}

.message-box.blue-red::after {
  background: var(--blue);
}

.message-box.blue-red {
  border: 1px solid var(--blue);
}

.message-box.from-me.blue-red {
  border: 1px solid var(--blue);
}

.blue-red .message-name {
  color: var(--blue);
}

.box-component.blue-red::after {
  opacity: 0.3;
  background: var(--blue-red);
}

.profile-background.blue-red {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(134.57deg, var(--blue) 10%, var(--red) 70%, #560216 90%);
  color: white;
}

/* Red Blue Gradient */

.box-component.red-blue::after {
  opacity: 0.3;
  background: var(--red-blue);
}

/* Orange Pink Gradient */

.profile-pic.orange-pink {
  border: none;
  color: var(--pink);
}

.user-list-item .profile-pic.orange-pink {
  margin-left: 2.5px;
  margin-right: 17.5px;
}

.profile-pic.orange-pink::after {
  background: var(--orange-pink);
}

.profile-pic.orange-pink::before {
  content: '';
  position: absolute;
  background: var(--orange-pink);
  top: -2.5px;
  left: -2.5px;
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  border-radius: 50%;
  opacity: 1;
  z-index: -1;
}

.single-chat-container .profile-pic.orange-pink::before {
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
}

.profile-pic.orange-pink .online-dot,
.unread-dot.orange-pink,
.unread-dot.orange-pink::after {
  background: var(--orange);
}

.heading-bar-background-2.orange-pink {
  background: var(--orange-pink);
}

.box-component.orange-pink::after {
  opacity: 0.2;
  background: var(--orange-pink);
}

/* Orange */

.profile-pic.orange {
  border-color: var(--orange);
  color: var(--orange);
}

.profile-pic.orange .online-dot,
.unread-dot.orange,
.unread-dot.orange::after,
.heading-bar-background-2.orange,
.profile-pic.orange::after,
.profile-pic.orange::after,
.swatch.orange,
.swatch.orange::after {
  background: var(--orange);
}

.orange .message-name {
  color: var(--orange);
}

.profile-background.orange {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(134.57deg, var(--orange) 8.74%, #211104 100%);
  color: white;
}

.message-box.from-me.orange {
  background: linear-gradient(134.57deg, var(--orange) 8.74%, #c96d03 100%);
}

.message-box.from-me.orange:after {
  opacity: 0.85;
}

.message-box.orange {
  border: 1px solid var(--orange);
}

.message-box.orange::after {
  background: var(--orange);
  opacity: 0.3;
}

/* Yellow */

.profile-pic.yellow {
  border-color: var(--yellow);
  color: var(--yellow);
}

.profile-pic.yellow::after {
  background: var(--yellow);
}

.profile-pic.yellow::after {
  background: var(--yellow);
}

.profile-pic.yellow .online-dot,
.unread-dot.yellow,
.unread-dot.yellow::after {
  background: var(--yellow);
}

.heading-bar-background-2.yellow {
  background: var(--yellow);
}

.yellow .message-name {
  color: var(--yellow);
}

.box-component.yellow::after {
  opacity: 0.2;
  background: linear-gradient(282.97deg, rgba(255, 150, 28, 1) 10%, rgba(70, 70, 70, 1) 100%);
}

.notice-overlay.yellow {
  border-color: var(--yellow);
  color: var(--yellow);
}

.notice-overlay.yellow a {
  color: var(--yellow);
}

.swatch.yellow,
.swatch.yellow::after {
  background: var(--yellow);
}

.profile-background.yellow {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(134.57deg, var(--yellow) 8.74%, #211804 100%);
  color: white;
}

.profile-background.yellow {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(134.57deg, var(--orange) 8.74%, #211104 100%);
  color: white;
}

.message-box.from-me.yellow {
  background: linear-gradient(134.57deg, var(--yellow) 8.74%, #cfa200 100%);
}

.message-box.from-me.yellow:after {
  opacity: 0.85;
}

.message-box.yellow {
  border: 1px solid var(--yellow);
}

.message-box.yellow::after {
  background: var(--yellow);
  opacity: 0.3;
}

/* Green */

.profile-pic.green {
  border-color: var(--green);
  color: var(--green);
}

.profile-pic.green::after {
  background: var(--green);
}

.profile-pic.green .online-dot,
.unread-dot.green,
.unread-dot.green::after {
  background: var(--green);
}

.heading-bar-background-2.green {
  background: var(--green);
}

.message-box.from-me.green {
  background: var(--green-gradient);
}

.message-box.from-me.green:after {
  opacity: 0.6;
}

.message-box.green {
  border: 1px solid var(--green);
}

.message-box.green::after {
  background: var(--green);
  opacity: 0.35;
}

.green .message-name {
  color: var(--green);
}

.box-component.green::after {
  opacity: 0.4;
  background: linear-gradient(282.97deg, var(--green) 10%, rgba(70, 70, 70, 1) 100%);
}

.profile-background.green {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(134.57deg, var(--green) 8.74%, #04211c 100%);
  color: white;
}

.swatch.green,
.swatch.green::after {
  background: var(--green);
}

/* Red */

.profile-pic.red {
  border-color: var(--red);
  color: var(--red);
}

.profile-pic.red .online-dot,
.unread-dot.red,
.unread-dot.red::after,
.heading-bar-background-2.red,
.profile-pic.red::after,
.profile-pic.red::after,
.swatch.red,
.swatch.red::after {
  background: var(--red);
}

.red .message-name {
  color: var(--red);
}

.profile-background.red {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(134.57deg, var(--red) 8.74%, #210409 100%);
  color: white;
}

.box-component.red::after {
  opacity: 0.2;
  background: var(--red);
}

.notice-overlay.red {
  border-color: var(--red);
  color: var(--red);
}

.notice-overlay.red a {
  color: var(--red);
}

/* Blue */

.notice-overlay.blue {
  border-color: var(--blue);
  color: var(--blue);
}

.notice-overlay.blue a {
  color: var(--blue);
}

/* Sky Blue */

.profile-pic.sky-blue {
  border-color: var(--sky-blue);
  color: var(--sky-blue);
}

.profile-pic.sky-blue .online-dot,
.unread-dot.sky-blue,
.unread-dot.sky-blue::after,
.heading-bar-background-2.sky-blue,
.profile-pic.sky-blue::after,
.profile-pic.sky-blue::after,
.swatch.sky-blue,
.swatch.sky-blue::after {
  background: var(--sky-blue);
}

.sky-blue .message-name {
  color: var(--sky-blue);
}

.profile-background.sky-blue {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(134.57deg, var(--sky-blue) 8.74%, #041721 100%);
  color: white;
}

.message-box.from-me.sky-blue {
  background: linear-gradient(134.57deg, #3cabc1 8.74%, #177487 100%);
}

.message-box.from-me.sky-blue:after {
  opacity: 0.85;
}

.message-box.sky-blue {
  border: 1px solid var(--sky-blue);
}

.message-box.sky-blue::after {
  background: var(--sky-blue);
  opacity: 0.4;
}

/* Pink */

.profile-pic.pink {
  border-color: var(--pink);
  color: var(--pink);
}

.profile-pic.pink .online-dot,
.unread-dot.pink,
.unread-dot.pink::after,
.heading-bar-background-2.pink,
.profile-pic.pink::after,
.profile-pic.pink::after,
.swatch.pink,
.swatch.pink::after {
  background: var(--pink);
}

.pink .message-name {
  color: var(--pink);
}

.profile-background.pink {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(134.57deg, var(--pink) 8.74%, #210415 100%);
  color: white;
}

.message-box.from-me.pink {
  background: linear-gradient(134.57deg, #c21391 8.74%, #6e0851 100%);
}

.message-box.from-me.pink:after {
  opacity: 0.85;
}

.message-box.pink {
  border: 1px solid var(--pink);
}

.message-box.pink::after {
  background: var(--pink);
  opacity: 0.2;
}

/* Purple */

.profile-pic.purple {
  border-color: var(--purple);
  color: var(--purple);
}

.profile-pic.purple .online-dot,
.unread-dot.purple,
.unread-dot.purple::after,
.heading-bar-background-2.purple,
.profile-pic.purple::after,
.profile-pic.purple::after,
.swatch.purple,
.swatch.purple::after {
  background: var(--purple);
}

.purple .message-name {
  color: var(--purple);
}

.profile-background.purple {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(134.57deg, var(--purple) 8.74%, #0a0421 100%);
  color: white;
}

.message-box.from-me.purple {
  background: linear-gradient(134.57deg, var(--purple) 8.74%, #3b29ae 100%);
}

.message-box.from-me.purple:after {
  opacity: 0.65;
}

.message-box.purple {
  border: 1px solid var(--purple);
}

.message-box.purple::after {
  background: var(--purple);
  opacity: 0.28;
}

/* Kermit */

.profile-pic.kermit {
  border-color: var(--kermit);
  color: var(--kermit);
}

.profile-pic.kermit .online-dot,
.unread-dot.kermit,
.unread-dot.kermit::after,
.heading-bar-background-2.kermit,
.profile-pic.kermit::after,
.profile-pic.kermit::after,
.swatch.kermit,
.swatch.kermit::after {
  background: var(--kermit);
}

.kermit .message-name {
  color: var(--kermit);
}

.profile-background.kermit {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(134.57deg, var(--kermit) 8.74%, #132104 100%);
  color: white;
}

.message-box.from-me.kermit {
  background: linear-gradient(134.57deg, var(--kermit) 8.74%, #75b421 100%);
}

.message-box.from-me.kermit:after {
  opacity: 0.65;
}

.message-box.kermit {
  border: 1px solid var(--kermit);
}

.message-box.kermit::after {
  background: var(--kermit);
  opacity: 0.28;
}

/* Peach */

.profile-pic.peach {
  border-color: var(--peach);
  color: var(--peach);
}

.profile-pic.peach .online-dot,
.unread-dot.peach,
.unread-dot.peach::after,
.heading-bar-background-2.peach,
.profile-pic.peach::after,
.profile-pic.peach::after,
.swatch.peach,
.swatch.peach::after {
  background: var(--peach);
}

.peach .message-name {
  color: var(--peach);
}

.profile-background.peach {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(134.57deg, var(--peach) 8.74%, #210904 100%);
  color: white;
}

.message-box.from-me.peach {
  background: linear-gradient(134.57deg, var(--peach) 8.74%, #c74c4c 100%);
}

.message-box.from-me.peach:after {
  opacity: 0.65;
}

.message-box.peach {
  border: 1px solid var(--peach);
}

.message-box.peach::after {
  background: var(--peach);
  opacity: 0.28;
}

/* Lilac */

.profile-pic.lilac {
  border-color: var(--lilac);
  color: var(--lilac);
}

.profile-pic.lilac .online-dot,
.unread-dot.lilac,
.unread-dot.lilac::after,
.heading-bar-background-2.lilac,
.profile-pic.lilac::after,
.swatch.lilac,
.swatch.lilac::after {
  background: var(--lilac);
}

.heading-bar-background-2.lilac {
  opacity: 0.8;
}

.profile-pic.lilac::after {
  opacity: 1;
}

.lilac .message-name {
  color: var(--lilac);
}

.profile-background.lilac {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(134.57deg, var(--lilac) 8.74%, #160421 100%);
  color: white;
}

.message-box.from-me.lilac {
  background: linear-gradient(134.57deg, var(--lilac) 8.74%, #a874bf 100%);
}

.message-box.from-me.lilac:after {
  opacity: 0.95;
}

.message-box.lilac {
  border: 1px solid var(--lilac);
}

.message-box.lilac::after {
  background: var(--lilac);
  opacity: 0.48;
}

/* Pastel Green */

.profile-pic.pastel-green {
  border-color: var(--pastel-green);
  color: var(--pastel-green);
}

.profile-pic.pastel-green .online-dot,
.unread-dot.pastel-green,
.unread-dot.pastel-green::after,
.heading-bar-background-2.pastel-green,
.profile-pic.pastel-green::after,
.swatch.pastel-green,
.swatch.pastel-green::after {
  background: var(--pastel-green);
}

.profile-pic.pastel-green::after {
  opacity: 1;
}

.pastel-green .message-name {
  color: var(--pastel-green);
}

.profile-background.pastel-green {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(134.57deg, var(--pastel-green) 8.74%, #042113 100%);
  color: white;
}

.message-box.from-me.pastel-green {
  background: linear-gradient(134.57deg, #7bd29a 8.74%, #59b278 100%);
}

.message-box.from-me.pastel-green:after {
  opacity: 0.85;
}

.message-box.pastel-green {
  border: 1px solid var(--pastel-green);
}

.message-box.pastel-green::after {
  background: var(--pastel-green);
  opacity: 0.38;
}

/* Green Blue Gradient */

.swatch.green-blue,
.swatch.green-blue::after,
.event-tile.green-blue,
.event-tile.green-blue::after,
.event-body.green-blue {
  background: var(--green-blue);
}

.profile-pic.green-blue {
  border: none;
  color: var(--green);
}

.user-list-item .profile-pic.green-blue {
  margin-left: 2.5px;
  margin-right: 17.5px;
}

.profile-pic.green-blue::after {
  background: var(--green-blue);
}

.profile-pic.green-blue::before {
  content: '';
  position: absolute;
  background: var(--green-blue);
  top: -2.5px;
  left: -2.5px;
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  border-radius: 50%;
  opacity: 1;
  z-index: -1;
}

.single-chat-container .profile-pic.green-blue::before {
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
}

.profile-pic.green-blue .online-dot,
.unread-dot.green-blue,
.unread-dot.green-blue::after {
  background: var(--green);
}

.box-component.green-blue::after {
  opacity: 0.2;
  background: var(--green-blue);
}

/* Blue Pink Gradient */

.swatch.blue-pink,
.swatch.blue-pink::after,
.event-tile.blue-pink,
.event-tile.blue-pink::after,
.event-body.blue-pink {
  background: var(--blue-pink);
}

.profile-pic.blue-pink {
  border: none;
  color: var(--blue);
}

.user-list-item .profile-pic.blue-pink {
  margin-left: 2.5px;
  margin-right: 17.5px;
}

.profile-pic.blue-pink::after {
  background: var(--blue-pink);
}

.profile-pic.blue-pink::before {
  content: '';
  position: absolute;
  background: var(--blue-pink);
  top: -2.5px;
  left: -2.5px;
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  border-radius: 50%;
  opacity: 1;
  z-index: -1;
}

.single-chat-container .profile-pic.blue-pink::before {
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
}

.profile-pic.blue-pink .online-dot,
.unread-dot.blue-pink,
.unread-dot.blue-pink::after {
  background: var(--pink);
}

.box-component.blue-pink::after {
  opacity: 0.2;
  background: var(--blue-pink);
}

/* Orange Pink Gradient */

.swatch.orange-pink,
.swatch.orange-pink::after,
.event-tile.orange-pink,
.event-tile.orange-pink::after,
.event-body.orange-pink {
  background: var(--orange-pink);
}

/* Pink Green Gradient */

.swatch.pink-green,
.swatch.pink-green::after,
.event-tile.pink-green,
.event-tile.pink-green::after,
.event-body.pink-green {
  background: var(--pink-green);
}

.profile-pic.pink-green {
  border: none;
  color: var(--pink);
}

.user-list-item .profile-pic.pink-green {
  margin-left: 2.5px;
  margin-right: 17.5px;
}

.profile-pic.pink-green::after {
  background: var(--pink-green);
}

.profile-pic.pink-green::before {
  content: '';
  position: absolute;
  background: var(--pink-green);
  top: -2.5px;
  left: -2.5px;
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  border-radius: 50%;
  opacity: 1;
  z-index: -1;
}

.single-chat-container .profile-pic.pink-green::before {
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
}

.profile-pic.pink-green .online-dot,
.unread-dot.pink-green,
.unread-dot.pink-green::after {
  background: var(--pink);
}

.box-component.pink-green::after {
  opacity: 0.2;
  background: var(--pink-green);
}

/* Night Blue Gradient */

.swatch.night-blue,
.swatch.night-blue::after,
.event-tile.night-blue,
.event-tile.night-blue::after,
.event-body.night-blue {
  background: var(--night-blue);
}

.profile-pic.night-blue {
  border: none;
  color: var(--blue);
}

.user-list-item .profile-pic.night-blue {
  margin-left: 2.5px;
  margin-right: 17.5px;
}

.profile-pic.night-blue::after {
  background: var(--night-blue);
}

.profile-pic.night-blue::before {
  content: '';
  position: absolute;
  background: var(--night-blue);
  top: -2.5px;
  left: -2.5px;
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  border-radius: 50%;
  opacity: 1;
  z-index: -1;
}

.single-chat-container .profile-pic.night-blue::before {
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
}

.profile-pic.night-blue .online-dot,
.unread-dot.night-blue,
.unread-dot.night-blue::after {
  background: var(--blue);
}

.box-component.night-blue::after {
  opacity: 0.2;
  background: var(--night-blue);
}

/* Blue Orange Gradient */

.swatch.blue-orange,
.swatch.blue-orange::after,
.event-tile.blue-orange,
.event-tile.blue-orange::after,
.event-body {
  background: var(--blue-orange);
}

.profile-pic.blue-orange {
  border: none;
  color: var(--orange);
}

.user-list-item .profile-pic.blue-orange {
  margin-left: 2.5px;
  margin-right: 17.5px;
}

.profile-pic.blue-orange::after {
  background: var(--blue-orange);
}

.profile-pic.blue-orange::before {
  content: '';
  position: absolute;
  background: var(--blue-orange);
  top: -2.5px;
  left: -2.5px;
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  border-radius: 50%;
  opacity: 1;
  z-index: -1;
}

.single-chat-container .profile-pic.blue-orange::before {
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
}

.profile-pic.blue-orange .online-dot,
.unread-dot.blue-orange,
.unread-dot.blue-orange::after {
  background: var(--orange);
}

.box-component.orange-blue::after {
  opacity: 0.2;
  background: var(--orange-blue);
}

/* Variables */

:root {
  --blue: #007faf;
  --red: #cb0935;
  --orange: #ff961c;
  --standard-green: #77b12a;
  --kermit: #8bd826;
  --pastel-green: #88e1a7;
  --pink: #c21391;
  --grey: #979797;
  --sky-blue: #53d7f2;
  --purple: #7664e8;
  --peach: #ff6363;
  --lilac: #dbb4ec;
  --light-grey: #f6f6f6;
  --border-grey: #b7b7b7;
  --heading-black: #464646;
  --body-black: #535353;
  /* event backgrounds */
  --green-blue: linear-gradient(141.74deg, #91d500 3.25%, #13d0a3 97.3%);
  --blue-pink: linear-gradient(141.74deg, #1aa1bd 3.25%, #c213b1 97.3%);
  --orange-pink: linear-gradient(141.74deg, #e8770f 3.25%, #c21391 97.3%);
  --pink-green: linear-gradient(141.74deg, #ff0090 4.25%, #4bffd0 100%);
  --night-blue: linear-gradient(141.74deg, #4b6cb7 0%, #182848 100%);
  --blue-orange: linear-gradient(141.74deg, #007aeb 3.25%, #ec4700 97.3%);
  /* profile colour options */
  --blue-message-glow: #00bbdf;
  --blue-red-general: linear-gradient(306.05deg, #cb0935 21.99%, #007faf 59.02%);
  --blue-red: linear-gradient(133.32deg, #007faf 16%, #cb0935 84.06%);
  --red-blue: linear-gradient(133.32deg, #cb0935 16%, #007faf 84.06%);
  --yellow: #ffc700;
  --green: #13e4bf;
  --green-gradient: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(164.44deg, #13e4bf 26.07%, #04211c 183.34%);
  --blue-gradient: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(164.44deg, #007faf 26.07%, #04211c 183.34%);
}
