/***************** Admin */

/* Admin Navigation */

.admin-nav {
  margin: 0;
  display: flex;
  align-items: center;
  overflow-x: scroll;
  overflow-y: visible;
  -webkit-overflow-scrolling: touch;
  padding: 15px 16px;
  justify-content: flex-start;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  z-index: 3;
  position: sticky;
  top: 0;
  background: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

.ios .admin-nav {
  padding-top: 55px;
}

.admin-nav::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.admin-nav a {
  white-space: nowrap;
  display: block;
  background: none;
  padding: 8px 14px;
  margin-right: 7px;
  color: var(--grey);
  border: 2px solid transparent;
  border-radius: 50px;
}

.admin-nav a.active {
  font-weight: 600;
  color: var(--blue);
  border-color: var(--blue);
}

/* General */

.admin-page-container {
  padding-left: 20px;
  padding-right: 20px;
  background: transparent;
  min-height: calc(100% - 70px);
  padding-top: 85px;
  padding-bottom: 30px;
  -webkit-overflow-scrolling: touch;
}

.ios .admin-page-container {
  padding-top: 125px;
}

.admin-page-container h3 {
  margin: 20px 0px 20px;
}

.admin-page-container .notices-container {
  top: 40px;
}

/* Verfy User */

.verify-user-container {
  padding-bottom: 80px;
}

.verify-user-container .checkbox-container {
  margin: 20px 0;
}

.verify-user-container .checkbox-container p,
.event-community-select .checkbox-container p {
  color: var(--body-black);
  font-weight: 600;
}

.new-user-data {
  -webkit-user-select: text;
  user-select: text;
}

.new-user-data p {
  margin: 0;
}

.new-user-data .label {
  font-size: 16px;
  margin-bottom: 5px;
}

.new-user-data .user-data {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0px;
}

.new-user-data p:last-of-type {
  margin-bottom: 0;
}

.user-bio {
  white-space: pre-wrap;
}

/* Events */

.event-text-input {
  border: none;
  -webkit-appearance: none;
  border-radius: 17px;
  padding: 11px 18px;
  box-shadow: 0px 0px 19px rgb(0 0 0 / 11%);
  width: 100%;
  margin-bottom: 15px;
  font-size: 19px;
  color: #7e7e7e;
  outline: none;
  height: 148px;
}

.event-community-select {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 374px) {
  .event-community-select {
    flex-direction: column;
  }
  .checkbox-container:not(:last-of-type) {
    margin-bottom: 7px;
  }
  .checkbox-container:last-of-type {
    margin-bottom: 20px;
  }
}

.create-event .swatch-container {
  margin-bottom: 110px;
}

.last-tile-container {
  margin-bottom: 110px;
}

.event-tile .close-icon {
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 2;
}

/* Broadcasts */

.broadcast-text-input-container {
  height: 148px;
  position: relative;
}

.broadcast-text-input {
  border: none;
  -webkit-appearance: none;
  border-radius: 17px;
  padding: 11px 18px;
  width: 100%;
  margin-bottom: 15px;
  font-size: 19px;
  color: #7e7e7e;
  outline: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.broadcast-text-input-container::before {
  background: var(--blue-red);
  -webkit-filter: blur(34px);
  filter: blur(34px);
  opacity: 0.3;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 17px;
  z-index: 0;
}

/* Admin user profile */

.admin-user-profile {
}

/* Notifications */

.notification.box-component h4 {
  margin-bottom: 10px;
}

.notification-timestamp {
  font-weight: 400;
  font-size: 15px;
  float: right;
  color: var(--border-grey);
}

.notification-message {
  margin-bottom: 10px;
}

.notification-message i {
  display: block;
}

.notification a,
.actions-container p,
.admin-quickview a,
.report a {
  font-weight: 600;
  color: var(--blue);
  margin: 0;
}

.report .red-link {
  color: var(--red);
}

.report h4 {
  margin-bottom: 5px;
}

.report .notification-message i {
  margin-bottom: 15px;
  font-size: 15px;
}

.report .notification-message {
  margin-bottom: 15px;
}

.admin-quickview.red a {
  color: var(--red);
}

.admin-quickview.yellow a {
  color: var(--yellow);
}

.actions-container {
  display: flex;
  justify-content: space-between;
}

.box-component .admin-quickview:last-of-type p {
  margin-bottom: 0;
}

/* All Chats */

.admin-page-container .chats-list {
  margin-left: -20px;
  margin-right: -20px;
}

/* Media Queries */

@media screen and (min-width: 800px) {
  .admin-page-container {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  .admin-page-container .glow-element-button.fixed-button {
    width: 500px;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .admin-nav {
    top: 85px;
    background: transparent;
    justify-content: center;
  }
  .admin-page-container .notices-container {
    bottom: 19px;
    top: unset;
  }
  .create-event .swatch-container {
    margin-bottom: 170px;
  }
  .admin-page-container .chats-list {
    border-right: none;
  }
}
